<template>
  <div class="dashboard-container">
    <el-row :gutter="12">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Reward Scheme Analytic</div>
          <div v-if="stats.config">
            <div style="margin-top:8px">Running : {{stats.config.IsRunning}}</div>
            <div style="margin-top:8px">Next Target : {{stats.config.NextTarget}} + ({{stats.config.CurrentBase}})</div>
            <div style="margin-top:8px">Block : {{stats.config.BlockId}}</div>
            <div style="margin-top:8px">Current Price : {{stats.config.CurrentPrice}}</div>
            <div style="margin-top:8px">Total POT Issued : {{stats.config.TotalPOTIssue}}</div>
            <div style="margin-top:8px">Total Reward Issued(USD) : {{stats.config.TotalRewardInUSD}}</div>
            <div style="margin-top:8px">Total Trade Volume(USD) : {{stats.config.TotalTradeVolumeInUSD}}</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="12" style="margin-top: 12px">
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Total User Registration</div>
          <div style="font-size:32px">{{ stats.TotalRegisteredUser }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Yesterday User Registration</div>
          <div style="font-size:32px">{{ stats.YesterdayTotalRegisteredUser }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Yesterday Total Trade User</div>
          <div style="font-size:32px">{{ stats.YesterdayTotalTradeUser }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Current Total Trade User</div>
          <div style="font-size:32px">{{ stats.CurrentTotalTradeUser }}</div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="12" style="margin-top: 12px">
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Today Claim POT Amount</div>
          <div style="font-size:32px">{{ stats.TodayClaimPotAmount }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Total Claim POT Amount</div>
          <div style="font-size:32px">{{ stats.TotalClaimPotAmount }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Today Claim USDT Amount</div>
          <div style="font-size:32px">{{ stats.TodayClaimUsdtAmount }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Total Claim USDT Amount</div>
          <div style="font-size:32px">{{ stats.TotalClaimUsdtAmount }}</div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="12" style="margin-top: 12px">
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Today Deposit</div>
          <div style="font-size:32px">{{ stats.TodayDeposit }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Total Deposit</div>
          <div style="font-size:32px">{{ stats.TotalDeposit }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Today Withdraw</div>
          <div style="font-size:32px">{{ stats.TodayWithdraw }}</div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Total Withdraw</div>
          <div style="font-size:32px">{{ stats.TotalWithdraw }}</div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="12" style="margin-top: 12px">
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">Today Login User</div>
          <div style="font-size:32px">{{ stats.TodayLoginUser }}</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Dashboard",
  data() {
    return {
      stats: {},
    };
  },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request({
        url: "/api/admin/dashboard/statistics",
        method: "get",
      }).then(response => {
        this.stats = response.data.Data;
      });
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  .welcome {
  }
}
</style>
